<template>
    <div class="contents allVod pb-0" >
        <!-- <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("site-control-ai-monitoring") }}</h1> -->
        <div class="content-row">
            <div class="ai-view" v-for="(index) in 3" :key="index">
                <span class="channel" style="z-index:101; width:50px; height:20px; text-align:center; line-height: 20px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.2rem; border-radius: 5px; " >CH.{{ index }}</span>
                <div class="play-off" v-if="getLiveData(index- 1) == undefined || getLiveData(index- 1).isLoad == false">
                  <img src="@/assets/images/play-off-icon.png" alt="icon" />
                  <h2>영상 신호 없음</h2>
                </div>
                <div  >
                  <Video v-if="getLiveData(index - 1) != undefined" v-show="getLiveData(index - 1).isLoad == true" class="play-on"  :src="getLiveUrl(getLiveData(index - 1))" :isVod="false" :isAuto="true" :isControls="false" :playerId="getLiveData(index - 1).channelId" @playerRemoveId="playerRemove" @isLoadedmetadata="playerLoadData"/>
                </div>

                <div class="ai-view-info">
                    <h4>
                        <span>모델</span>
                        <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? 'N/A' : getLiveData(index - 1).assignedDeviceName}}</span>
                    </h4>
                    <h4>
                        <span>소속</span>
                        <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false ? 'N/A' : getLiveData(index - 1).assignedDepartmentName}}</span>
                    </h4>
                    <h4>
                        <span>조종자</span>
                        <span>{{getLiveData(index - 1) == undefined || getLiveData(index - 1).isLoad == false? 'N/A' : getLiveData(index - 1).assignedUserName}}</span>
                    </h4>
                </div>
            </div>
            <div class="map-content">
                <div class="map">
                    <Map ref="map" style="width: 100%; height: 100%; border-radius:5px;" :loadMapName="loadMap" :isLeftButtonPosition="false" @map-move-center="onMapCenter"/>
                    <span v-if="locationSize == 0" style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                        {{$t("popup-location-not-found")}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.title{display: none;}
.lnb{display: none;}
.m-b-20{margin-bottom: 20px;}
.m-b-5{margin-bottom: 5px;}
.pb-0{padding-bottom: 0px;}

.content-row{display: flex;justify-content: space-between;align-items: center;flex-direction: row;flex-wrap: wrap;margin-top: 60px;}

.map-content{width: 49.5%;}
.map-content > .map{height: 430px;}

.ai-view{position: relative; width: 49.5%;margin: 5px 0;height: 430px;background: #000000;position: relative;}
.ai-view > .play-on{width: 100%;height: 100%;}
.ai-view > .play-off{width: 100%;text-align: center;position:absolute;transform: translate(-50%, -50%);top: 50%;left: 50%;}
.ai-view > .play-off > h2{font-size: 1.4rem;color: #fff;margin-top: 10px;}
.ai-view > .play-off > img{width: 30px;}

.ai-view-info{position: absolute;bottom: 0;z-index: 100;width: 100%;display: flex;justify-content: flex-start;align-items: center;border-top: 1px solid #707070;background: #232323bf;padding: 5px 15px;}
.ai-view-info > h4{margin-right: 20px;}
.ai-view-info > h4 > span{color: #fff;font-size: 1.2rem;font-weight: 300;}
.ai-view-info > h4 > span:nth-child(2){margin-left: 10px;}
</style>

<script>
import moment from "moment";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";

import { fetchLocation } from "@/api/location";

import { fetchEvent} from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";

import {mapState} from "vuex";
import {getDefaultMapZoomLevel} from "@/util/common";
import {ko} from "vuejs-datepicker/dist/locale";
import {fetchChannelList, getLiveUrl, fetchChannelCheckStream} from "@/api/channel";
import Video from "@/components/video/videoPlayer";

export default {
components: { Map, Video},
name: "Main",
props: {
    eventId: {
    type: String,
    default: "",
    },
    address: {
    type: String,
    default: "",
    },
    updatedDate : {
      type: String,
      default: ""
    },
},
data() {
    return {
    //index: 0,
    moment: moment,
    loadMap : 'kakao',
    viewSrc: "",

    isdrModelShow:true,
    eventInfo : null,
    contentsId: null,
    location: [],
    lat : null,
    lng : null,
    locationSize : null,
    ko: ko,
    startYmd: null,
    endYmd: null,
    currentPage: 0,
    size: 10,
    keywordType: "all",
    keyword: "",
    total: 0,

    liveSize : 3,
    channelStreamIds : [],
    currentChannel : null,
    viewChannelList:[],
    liveList : [],
    channelInterval : null,

    windowWidth: null,
    checkedStations: []
    };
},
computed: {
    ...mapState({
    currentLang: state => state.store.currentLang,
    userType: state => state.store.userType,
    accountId: state => state.store.accountId,
    }),
},
watch : {
    currentLang(value){
    this.initMapData(value);
    },
    channelStreamIds(value){
      if(value.length > 4) {
        this.channelStreamIds.splice(this.liveSize, 1);
      }
    },
    windowWidth(){
    this.windowWidth = window.innerWidth;
    this.handleResize()
    }
},
mounted() {
    // await this.getSDList();
    this.getEvent();
    this.getLiveList();
    this.channelInterval = setInterval(() => {
      this.getLiveList();
    }, 2000);
    this.initMapData(this.$store.state.store.currentLang );
},
created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 2 });
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
},
methods: {
    async getLiveList() {
      let params = {
        pageNumber: 0,
        pageSize: this.liveSize,
        assignedStatus: "Assigned",
        isDepartment :  this.isAdmin ? false : true
      };
      await fetchChannelList(params).then((res) => {

        this.total = res.data.data.total;
        this.liveList = res.data.data.content;

        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if(findIndex != -1) {
            delIds.splice(findIndex,1);
          }
        });

        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);
          if(findIndex != -1){

            let channel = this.viewChannelList[findIndex];
            if(this.currentLive != null && this.currentLive.channelId == channel.channelId) {
              this.clickLive(channel)
            }
            // this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);
            this.viewChannelList.splice(findIndex,1);
          }
        })

        //채널 추가
        for(var i = 0; i < this.liveList.length ; i++) {
          this.addStreamChanelToChannel(this.liveList[i])
        }
      });
    },
    addStreamChanelToChannel(channel){
      fetchChannelCheckStream(channel.channelId).then((res)=>{
        if (res.data.result == 0 || res.data.data.bytesIn != 0) {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == channel.channelId);
          if(findIndex == -1){
            channel.isLoad = false;
            this.viewChannelList.push(channel)
          }
        }
      })
    },
    channelChange(){
      if(this.channelStreamIds.length > 3) {
        this.channelStreamIds.pop()
      }
    },

    playerRemove(id){
      let findIndex = this.viewChannelList.findIndex(item => item.channelId == id);
      if(findIndex != -1){
        let channel = this.viewChannelList[findIndex];
        if(this.currentLive != null && this.currentLive.channelId == channel.channelId) {
          this.clickLive(channel);
        }
        this.viewChannelList.splice(findIndex,1);
      }
    },
    playerLoadData(id, isLoad) {
      console.log("playerLoadData", id, isLoad);
      var isFindIndex = (data) => {
        return data.channelId == id;
      };


      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if (findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getLiveData(index) {
      if(this.viewChannelList[index] == undefined) {
        return;
      }
      return this.viewChannelList[index];
    },
    getLiveUrl(channelData) {
      var url = getLiveUrl(channelData);

      if (url == '-') {
        return ''
      }
      let findLive = url.slice(url.indexOf("/live")+ "/live".length + 1, url.indexOf("/playlist"));
      let resultUrl = url.replace(findLive,"AI_"+ findLive)
      return resultUrl
    },

    onContentsDelete(){
    this.$refs.vodList.getContentsList()();
    },
    handleResize(){
    this.windowWidth = window.innerWidth;
    if(this.windowWidth < 1650){
        this.isdrModelShow = false;
    }else{
        this.isdrModelShow = true;
    }

    },
    initMapData(value){
    if(value == 'en') {
        this.loadMap = 'google'
    } else {
        this.loadMap = 'kakao'
    }
    this.getEventPoint();
    this.setMapSetting();
    if(this.contentsId != null) {
        this.getLocation(this.contentsId);
    }
    },
    setMapSetting(){
    this.$refs.map.onShowCenterPoint();
    this.$refs.map.onShowButtonTypeChange();
    //this.$refs.map.onShowLngLat();
    },
    getEvent() {
    if(this.eventId == undefined) {
        return;
    }
    fetchEvent(this.eventId).then((res) => {
        if (res.data.result == 0) {
        this.eventInfo = res.data.data;
        if(this.$refs.map == undefined) {
            return;
        }
        if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
        } else {
            this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        }
        }
    });
    },
    onMapCenter() {
    if (this.eventInfo == null) {
        return;
    }
    this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
    if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
        this.lng = this.eventInfo.lng;
        this.lat = this.eventInfo.lat;
        this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
        this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
    } else {
        this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
    }
    },
    async getEventPoint() {
    let params = {
        eventId : this.eventId,
        serverTime : null,
    };
    await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
        this.pointServerTime = res.data.data.serverTime
        this.pointCount = res.data.data.total
        this.pointList = res.data.data.content
        this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
        })
        }
    });
    },
    onTimeUpdate(time) {
    if(this.locationSize > 0) {
        this.$refs.map.onPlayUpdatePosition(time);
    }
    },
    async getLocation(contentsId){
    this.location = [];
    this.initDoneData();

    this.$refs.map.onPlayRemoveData()
    this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
    let params = {
        contentsId: contentsId,
    };
    this.locationSize = null;
    await fetchLocation(params).then((res) => {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
        if (res.data.result == 0) {
        this.locationSize = res.data.data.content.length;
        if( this.locationSize == 0) {
            return;
        }
        this.location = res.data.data.content.sort(function(a, b) {
            return a.index - b.index;
        })
        var first = res.data.data.content[0];
        this.lng = first.lng;
        this.lat = first.lat;
        this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
        var mapLineData = new MapLineData();
        mapLineData.id = this.contentsId;
        mapLineData.locationGroup = res.data.data.content;
        this.$refs.map.onPlayCreateData(mapLineData);
        } else {
        this.locationSize = null;
        }
    });
    },
    async viewVideo(data) {
    this.viewSrc = data.src;
    this.isPlayStatus = false;

    if(this.contentsId != data.contentsId) {
        this.contentsId = data.contentsId;
        await this.getLocation(this.contentsId);
    }
    },
    customFormatter(date) {
    return moment(date).format("YYYY년 MM월 DD일");
    },
    search() {
    if (this.startYmd != null) {
        if (this.startYmd > this.endYmd || !this.endYmd || !this.startYmd) {
        return alert(this.$t("alert-message-check-regdate"));
        }
    }
    this.currentPage = 0;
    this.getVodList();
    },
    getVodList() {
    if(this.$refs.map != undefined) {
        this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
    }

    let params = {
        pageNumber: this.currentPage,
        pageSize: 8,
        eventId: this.eventId,
        deviceType: 'Drone,Mobile',
        keyword: this.keyword,
        keywordType: this.keywordType,
        eventStatus: 'Open',
    };
    if (this.startYmd && this.endYmd) {
        let from = this.startYmd ? moment(this.startYmd).format("YYYY-MM-DD") : null;
        let to = this.endYmd ? moment(this.endYmd).format("YYYY-MM-DD") : null;
        params["registeredDateRange.from"] = from;
        params["registeredDateRange.to"] = to;
    }
    },

    reset(){
    this.currentPage = 0;
    this.keyword = "";
    this.keywordType = "all";
    this.startYmd = null;
    this.endYmd = null;
    },
    onUpdateAiDetecting(selectAiDetecing){
    //추후 ai서버와 연결 api메소드 추가
    console.log(selectAiDetecing);
    },
    beforeDestroy() {
      clearInterval(this.channelInterval);
    },

},

};
</script>
